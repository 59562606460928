.modal_newsletter {
    &--body {
        background-color: $white;
        padding: 10px;
        max-width: 550px;
        @include mobile() {
            padding: 20px;
        }
    }
    &--inner {
        display: flex;
        align-items: center;
    }
    &--left,
    &--right {
        width: 50%;
        @include mobile() {
            width: 100%;
        }
    }
    &--right {
        @include mobile() {
            display: none;
        }
    }
    &--content {
        @include mobile-up() {
            padding-right: 15px;
        }
    }
    &--title {
        @include yanone22(700, $green);
        text-align: center;
    }
    &--text {
        color: $green-dark;
        text-align: center;
        margin-top: 20px;
    }
    &--img {
        position: relative;
        img {
            @include object(cover, 50% 50%);
        }
    }
    &--form {
        margin-top: 10px;
        &--row {
            margin-top: 10px;
        }
        &--label {
            border: 1px solid $green-dark;
            border-radius: 4px;
            padding: 10px 15px;
            display: flex;
            align-items: center;
            svg {
                width: 15px;
                margin-right: 10px;
            }
        }
        &--input {
            flex: 1 0 auto;
            background-color: $white;
            border: none;
            &:focus,
            &:focus-visible {
                outline: none;
            }
            &:visited {
                color: $green-dark;
                text-decoration: none;
            }
        }
        &--button {
            color: $white;
            text-transform: uppercase;
            background-color: $green-dark;
            border: 1px solid $green-dark;
            padding: 10px 15px;
            border-radius: 4px;
        }
    }
}